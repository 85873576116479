body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background : repeat rgba(235, 150, 108, 0.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  padding: 5px;
}

.main {
  position : relative;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background: black;
  border-radius: 10px;
  padding: 10px;
}

.submit-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  margin-top: 50px;
}

.submit-row {
  width: 100%;
  display: flex;
  /* background: yellow; */
  justify-content: space-between;
}

.notification {
  box-shadow: none!important;
  opacity: 1!important;
}

.submit-row input {
  flex: 1;
  margin: 0px 10px;
  font-size: 1rem;
  padding: 10px;
}


.mb-wrap {
  margin : 20px auto;
  padding : 20px;
  position : relative;
  width : 80%;
}

.mb-wrap p {
  margin : 0;
  padding : 0;
}

.mb-wrap blockquote {
  margin : 0;
  padding : 0;
  position : relative;
}

.mb-wrap cite {
  font-style : normal;
}

.mb-style-2 blockquote {
  padding-top : 150px;
}

.mb-style-2 blockquote:after {
    background: none repeat scroll 0 0 rgba(235, 150, 108, 0.8);
    border-radius: 50% 50% 50% 50%;
    color: rgba(255, 255, 255, 0.5);
    content: "❞";
    font-family: 'icons';
    font-size: 70px;
    height: 130px;
    left: 50%;
    line-height: 130px;
    margin-left: -65px;
    position: absolute;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
    top: 0;
    width: 130px;
}
.mb-style-2 blockquote:before {
    border-left: 5px solid rgba(235, 150, 108, 0.1);
    border-radius: 50% 50% 50% 50%;
    content: "";
    height: 500px;
    left: -50px;
    position: absolute;
    top: 0;
    width: 500px;
    z-index: -1;
}

.mb-style-2 blockquote p {
  /* background : none repeat scroll 0 0 rgba(255, 255, 255, 0.5); */
  box-shadow : 0 -6px 0 rgba(235, 150, 108, 0.2);
  color : rgba(235, 150, 108, 0.8);
  display : inline;
  font-family : Baskerville, Georgia, serif;
  font-style : italic;
  font-size : 28px;
  line-height : 46px;
  text-shadow : 0 1px 1px rgba(255, 255, 255, 0.5);
}

.mb-attribution {
  text-align : right;
}

 .mb-author {
  color : #D48158;
  font-size : 18px;
  font-weight : bold;
  padding-top : 10px;
  text-shadow : 0 1px 1px rgba(255, 255, 255, 0.1);
  text-transform : uppercase;
}

cite {
  color : #D7AA94;
  font-style : italic;
}

cite.owner {
  color: green;
}

cite:hover {
  color : #D48158;
}

.status {
  color: white;
  font-family: Helvetica;
  padding: 15px 30px;
  font-size: 19px;
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.pending_tx {
  background: #3C85F6;
}

.confirmed_tx {
  background: #23551D;
}

.pending_tx a {
  text-decoration: underline;
  color: white;
  margin-left: 10px;
  cursor: pointer;
}

.confirmed_tx a {
  text-decoration: underline;
  color: white;
  margin-left: 10px;
  cursor: pointer;
}


.action_button {
  margin-left: 10px!important;
}